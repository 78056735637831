import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import ConfigTable from 'components/Software/Mobile_Access_Over_HTTPS/configTable';
import NavButtons from 'components/Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Audio over HTTPS",
  "path": "/Software/Mobile_Access_Over_HTTPS/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "3-way to use the audio function if your camera has been integrated via HTTPS",
  "social": "/images/Search/QI_SearchThumb_SetupWizard.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-iOS-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Audio over HTTPS' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='3-way to use the audio function if your camera has been integrated via HTTPS' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Mobile_Access_Over_HTTPS/' locationFR='/fr/Software/Mobile_Access_Over_HTTPS/' crumbLabel="HTTPS" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-full-hd-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-full-hd-camera-series",
        "aria-label": "instar full hd camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Full HD Camera Series`}</h1>
    <h2 {...{
      "id": "audio-over-https",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#audio-over-https",
        "aria-label": "audio over https permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Audio over HTTPS`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-wege-die-audiofunktion-zu-nutzen-wenn-ihre-kamera-%C3%BCber-https-eingebunden-wurde"
        }}>{`2 Wege die Audiofunktion zu nutzen, wenn Ihre Kamera über HTTPS eingebunden wurde`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nutzen-sie-den-zugriff-%C3%BCber-p2p"
            }}>{`Nutzen Sie den Zugriff über P2P`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nutzen-sie-rtsp-videostream"
            }}>{`Nutzen Sie RTSP Videostream`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#nutzen-sie-http-videostream"
            }}>{`Nutzen Sie HTTP Videostream`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "2-ways-to-use-the-audio-function",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-ways-to-use-the-audio-function",
        "aria-label": "2 ways to use the audio function permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2-ways to use the audio function`}</h3>
    <p><em parentName="p">{`when your camera has been connected via HTTPS`}</em></p>
    <p>{`If you've followed `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Our Tutorial`}</a>{` for port forwarding for your `}<strong parentName="p">{`1080p camera`}</strong>{`, you have set the HTTPS port of the camera to 8081 and this, together with the RTMP port, is then routed in your router to allow access via the `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`DDNS Address`}</a>{`.`}</p>
    <p>{`If you call the DDNS address on your laptop via web browser, you will hear the sound of the microphone of the camera. However, when you add the camera in the mobile App InstarVision via the DDNS address, neither the sound is played, nor is it possible to use the opposing function. In the following, we describe 3 options for using the audio function of your Full HD camera.`}</p>
    <h3 {...{
      "id": "access-over-p2p",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#access-over-p2p",
        "aria-label": "access over p2p permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access over P2P`}</h3>
    <p><strong parentName="p">{`Access the audio stream of the camera and the intercom function`}</strong></p>
    <p>{`In the instructions for the apps, you'll find a warning that accessing `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Point_to_Point/"
      }}>{`P2P`}</a>{` is generally slower than accessing the DDNS address. This mainly concerns the 720p camera models. With the Full HD (1080p) models a P2P service is used, which is much faster and provides more functions. It is therefore an alternative to access via DDNS.`}</p>
    <p>{`For instructions on how to integrate the camera using the P2P service, see the instructions for each application:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Windows_Phone/P2P/"
        }}>{`Windows Phone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Windows/InstarVision/Metro/P2P/"
        }}>{`Windows 8/10 (Metro)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/P2P/"
        }}>{`iPhone`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/P2P/"
        }}>{`iPad`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Android/InstarVision/P2P/"
        }}>{`Android`}</a></li>
    </ul>
    <h3 {...{
      "id": "use-the-rtsp-videostream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-the-rtsp-videostream",
        "aria-label": "use the rtsp videostream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use the RTSP Videostream`}</h3>
    <p><strong parentName="p">{`Access to the camera's audio stream`}</strong></p>
    <p>{`In the `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding Guide`}</a>{`, the HTTPS port on the `}<strong parentName="p">{`8081`}</strong>{` and the RTMP ports is `}<strong parentName="p">{`1935`}</strong>{` explained. The RTMP port is only required for the Flash plugin in the web interface. If you are using only the app from the road, the port does not need to be routed in 1935.`}</p>
    <p>{`In order to use the RTSP stream of your camera in the app, you must first pass the RTSP port. By default this is on port `}<strong parentName="p">{`554`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/243407424a935795296b1126132b4b81/0c69d/IP-Konfiguration_FHD-min.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+UlEQVQ4y42Sz08TQRTH9+/DxIPikZP+JyoHCsivYI0QxJs3SGwPJhrjj8QDB2MKIRw00G1TaVq625l5837MW7O7LRa04idvJ5tsPvN9s2+iB1sn99YO766Oanb1aHYtX28vH84sNm5drZlK487K4Vz1+P7zk7nqcbRcjyu1VqUWL9TihddxpRYv1VuVWmt+//Tx3qgejevh3un8/ulirbnxprVUb0aqAcGQt+wdgRECAkPosukEzX50gUQjIu5fDJJ0aKzLH/DGOutAgl4WsSCxR/JILMEAN5pDhxKFEIwx1toQwrQo59xgMEiSJE1TIhJhVc4y/S0DQJZlOubSVFXvvbXWGOO9FxGWvBHVQrbWIuK0WFUFgLQAAKSAuZBFJE1Ta62ITPNLOUkSAOACIholO+cQcbLVa8neeywoDnw12RSUm/3VN8YAQAiBx4ySSxkAvPfMLCJ/blH+rTKwBHHcdmlCgTGGma/J5VdVDQUaQqb5XHPZe09EiHj5goj/GDtyiPvIIb9h1Ov1+v3+RUEyZnJ4o8By/qoO5eD7ECjcMGfVTIKWF5aY2XtGZERlzFSjyZFMkv0H0fSLla/fzszTt+2XHzvV9z/fHbTO1zfjlc34yUZ7vSrG3CAfteyLD51XX853P3c/fe10t3faz3ba1e3O1m6w9hfMTu6tGbzXrwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/243407424a935795296b1126132b4b81/e4706/IP-Konfiguration_FHD-min.avif 230w", "/en/static/243407424a935795296b1126132b4b81/d1af7/IP-Konfiguration_FHD-min.avif 460w", "/en/static/243407424a935795296b1126132b4b81/5fe6f/IP-Konfiguration_FHD-min.avif 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/243407424a935795296b1126132b4b81/a0b58/IP-Konfiguration_FHD-min.webp 230w", "/en/static/243407424a935795296b1126132b4b81/bc10c/IP-Konfiguration_FHD-min.webp 460w", "/en/static/243407424a935795296b1126132b4b81/83245/IP-Konfiguration_FHD-min.webp 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/243407424a935795296b1126132b4b81/81c8e/IP-Konfiguration_FHD-min.png 230w", "/en/static/243407424a935795296b1126132b4b81/08a84/IP-Konfiguration_FHD-min.png 460w", "/en/static/243407424a935795296b1126132b4b81/0c69d/IP-Konfiguration_FHD-min.png 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/243407424a935795296b1126132b4b81/0c69d/IP-Konfiguration_FHD-min.png",
              "alt": "1080p WebUI Network Configuration",
              "title": "1080p WebUI Network Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "712px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e092f138dcc868e1747d74db844441c7/3d4b6/Portforwarding-Fritzbox-7490de_new_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABS0lEQVQoz4VSXXODIBDM//9veep0mnYmKApo5DuikeRhOxC1bdqah+UYmNu7270dqyQObwXIUeD9QGH1iLOL8HbK8QsTfIo2Prz/xK5oImjtUNMTTq1fyRKcucDbyxynucj0R7FvhNpEMGZAjhyC6/njCtk5UMrBKgFSNtiTiOCXYhsd9i6C1woFEWi4WQlF1YCXFRgTaFRAH27o/UK20WE6BDegZQujBvT+it7foDoLqyy8H7E/jjBmnMeN2yOnoxUWFe0ytAxZM2t7SGnBKgUtHazpVx23kAkL0qAsWjQiEUjILiWPcCYgnG9Zgi1nHzSc4PR9nJQcztfVycVpZ+9uP8eEHRERhBrUtM0jL06va/KIZx0qNULJIWun1ZCNScv9H7QeoFTISHe93gOMHpKGv6tuia5NQM1PYEJC2wE17/Dy+gFSclg74hMtKEVgFTt9EwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e092f138dcc868e1747d74db844441c7/e4706/Portforwarding-Fritzbox-7490de_new_05.avif 230w", "/en/static/e092f138dcc868e1747d74db844441c7/d1af7/Portforwarding-Fritzbox-7490de_new_05.avif 460w", "/en/static/e092f138dcc868e1747d74db844441c7/4fb90/Portforwarding-Fritzbox-7490de_new_05.avif 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e092f138dcc868e1747d74db844441c7/a0b58/Portforwarding-Fritzbox-7490de_new_05.webp 230w", "/en/static/e092f138dcc868e1747d74db844441c7/bc10c/Portforwarding-Fritzbox-7490de_new_05.webp 460w", "/en/static/e092f138dcc868e1747d74db844441c7/c4538/Portforwarding-Fritzbox-7490de_new_05.webp 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e092f138dcc868e1747d74db844441c7/81c8e/Portforwarding-Fritzbox-7490de_new_05.png 230w", "/en/static/e092f138dcc868e1747d74db844441c7/08a84/Portforwarding-Fritzbox-7490de_new_05.png 460w", "/en/static/e092f138dcc868e1747d74db844441c7/3d4b6/Portforwarding-Fritzbox-7490de_new_05.png 712w"],
              "sizes": "(max-width: 712px) 100vw, 712px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e092f138dcc868e1747d74db844441c7/3d4b6/Portforwarding-Fritzbox-7490de_new_05.png",
              "alt": "Portforwarding Rule for the RTSP Port",
              "title": "Portforwarding Rule for the RTSP Port",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/0c69d/Mobile_Access_RTSP_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA8klEQVQ4y62Sy26EIBiFeZKOSRlNhhGF6g9UFNFpmvT93+c0sOuywuIE2Hw5FxjnHEIIeO/xzjnebjc0TXNZbH48YJVCMAYfQqC739F2Hbq2RZfOf4pFInwfB14hYJtn6GHAqBTURbFPogzTfY/x+cywcRwvi53ThJf3+IkRBxG0lGXAQISvbcNKlLtUKXIJ0DqHsO+YiaC0LoJlIBHBLwtijLDWYih2aC3WdUXf95BS5qXKHXqPEALSvYrDfd8zdJqmOsDkTlcYJAONMbnD8zzhnKsXuZrDNMSyLPlR6u5Phwlcw2XuMAGrfew0RILVivwLOAAy9wX7P54AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bcd9b411d16ba1a15725c626f3a71ae9/e4706/Mobile_Access_RTSP_01.avif 230w", "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/d1af7/Mobile_Access_RTSP_01.avif 460w", "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/5fe6f/Mobile_Access_RTSP_01.avif 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bcd9b411d16ba1a15725c626f3a71ae9/a0b58/Mobile_Access_RTSP_01.webp 230w", "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/bc10c/Mobile_Access_RTSP_01.webp 460w", "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/83245/Mobile_Access_RTSP_01.webp 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bcd9b411d16ba1a15725c626f3a71ae9/81c8e/Mobile_Access_RTSP_01.png 230w", "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/08a84/Mobile_Access_RTSP_01.png 460w", "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/0c69d/Mobile_Access_RTSP_01.png 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bcd9b411d16ba1a15725c626f3a71ae9/0c69d/Mobile_Access_RTSP_01.png",
              "alt": "InstarVision - add a RTSP Camera",
              "title": "InstarVision - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/0c69d/Mobile_Access_RTSP_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABL0lEQVQ4y+2QTU7DMBSEfQ6KsNPipEmbOHZ+bMdtKqSqYsOCVeEESPQQIHERNiw45SC7EUKIUth38WmenkfzrCGccxRFAWMMqqqCtRZ1XaPrOvg3SikYYweh0RgsGgelLAKZZRnyPIeSEkopSClRlmWY0zRFHMdIkiTgZ48/xP3+coKUjpDQEaYXZ5hOIpB+tULnHLTWe4z5VKn24eVwRCoVfq+1gW5qlMsrJNtHiO0D0vsd5usbkPVmA7tcoNYajTGojQkasBattWjsMPtd02DZ91joBvn1Lc6f3pA/v4K+vCO+24G0bYvO2tDZTyycC7065+C9vu+iyIOK+QyKMwgeQXIGmU1BhBCD6TjeGyr4glAVSqWCCilBvhuO4UMP4d//HfjboVPgKfAU+NfAD+WPNF2IAuBqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e7c84cf16eb6b1e732daf87cae7344ef/e4706/Mobile_Access_RTSP_02.avif 230w", "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/d1af7/Mobile_Access_RTSP_02.avif 460w", "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/5fe6f/Mobile_Access_RTSP_02.avif 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e7c84cf16eb6b1e732daf87cae7344ef/a0b58/Mobile_Access_RTSP_02.webp 230w", "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/bc10c/Mobile_Access_RTSP_02.webp 460w", "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/83245/Mobile_Access_RTSP_02.webp 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e7c84cf16eb6b1e732daf87cae7344ef/81c8e/Mobile_Access_RTSP_02.png 230w", "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/08a84/Mobile_Access_RTSP_02.png 460w", "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/0c69d/Mobile_Access_RTSP_02.png 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e7c84cf16eb6b1e732daf87cae7344ef/0c69d/Mobile_Access_RTSP_02.png",
              "alt": "InstarVision - add a RTSP Camera",
              "title": "InstarVision - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/649c0be88c961fa86afe68835bdb8d89/0c69d/Mobile_Access_RTSP_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuElEQVQ4y+2QywqDQAxF50vEjYriqGPn/RC66P9/0i1JuykI6qobF4dAEg43EU3TQEqJEALWdYX3HsYYxBjRdR2qqkJd16cRwzBAjiPLlFI/9H2Ptm1ZfBaRS0FMCc45xlrLKanO84xlWS4hnq8Xwrbh4SyM99DeQdPZJA0BhvFcaUZ7+zisWkNQqpQScs5MiBEp50+vFO7Fb89Qakqi1D6UkM6apukUtHuEuPqjwx/ewlt4C/8hfAO0fDEr3YxVogAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/649c0be88c961fa86afe68835bdb8d89/e4706/Mobile_Access_RTSP_03.avif 230w", "/en/static/649c0be88c961fa86afe68835bdb8d89/d1af7/Mobile_Access_RTSP_03.avif 460w", "/en/static/649c0be88c961fa86afe68835bdb8d89/5fe6f/Mobile_Access_RTSP_03.avif 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/649c0be88c961fa86afe68835bdb8d89/a0b58/Mobile_Access_RTSP_03.webp 230w", "/en/static/649c0be88c961fa86afe68835bdb8d89/bc10c/Mobile_Access_RTSP_03.webp 460w", "/en/static/649c0be88c961fa86afe68835bdb8d89/83245/Mobile_Access_RTSP_03.webp 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/649c0be88c961fa86afe68835bdb8d89/81c8e/Mobile_Access_RTSP_03.png 230w", "/en/static/649c0be88c961fa86afe68835bdb8d89/08a84/Mobile_Access_RTSP_03.png 460w", "/en/static/649c0be88c961fa86afe68835bdb8d89/0c69d/Mobile_Access_RTSP_03.png 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/649c0be88c961fa86afe68835bdb8d89/0c69d/Mobile_Access_RTSP_03.png",
              "alt": "InstarVision - add a RTSP Camera",
              "title": "InstarVision - add a RTSP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ConfigTable mdxType="ConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Quality setting of the RTSP stream: `}<a parentName="p" {...{
        "href": "/en/Software/Mobile_Apps_HTTPS_and_RTSP/"
      }}>{`Configuration`}</a></p>
    <ul>
      <li parentName="ul">{`First set the RTSP port in the camera - by default, it is located on port 554, but it can also be placed on a higher port, eg 9091.`}</li>
      <li parentName="ul">{`Then, run the appropriate port in your router to the camera by following the `}<a parentName="li" {...{
          "href": "/en/Internet_Access/Port_Forwarding/"
        }}>{`Port Forwarding Guide`}</a>{`, as you did before for the 8081 port.`}</li>
      <li parentName="ul">{`Then go to the InstarVision mobile app and select a new IP camera, select the appropriate camera model with the RTSP addition and enter the two forwarded ports (HTTPS and RTSP).`}</li>
      <li parentName="ul">{`You will then be able to hear the audio stream of your camera - but do not use the intercom function.`}</li>
    </ul>
    <h3 {...{
      "id": "use-the-http-videostream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-the-http-videostream",
        "aria-label": "use the http videostream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use the HTTP Videostream`}</h3>
    <p><strong parentName="p">{`Access the audio stream of the camera and the intercom function`}</strong></p>
    <p>{`In the `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding Guide`}</a>{`, the HTTPS port on the `}<strong parentName="p">{`8081`}</strong>{` and the RTMP ports is `}<strong parentName="p">{`1935`}</strong>{` explained. The RTMP port is only required for the Flash plugin in the web interface. If you are using only the app from the road, the port does not need to be routed in 1935.`}</p>
    <p>{`If you set the HTTPS port of the camera back to the default value of 443 and change the HTTP port to 8081, you can delete the HTTPS port in the InstarVision app and enter the HTTP port with 8081 instead.`}</p>
    <p>{`Access over HTTP - but with encryption? `}<a parentName="p" {...{
        "href": "/en/Advanced_User/VPN_AVM_Fritzbox/"
      }}>{`VPN`}</a></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8f70fb2c6b6856efdd63360bc587b27c/0c69d/IP-Konfiguration_FHD-min_alt.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACL0lEQVQ4y41TXWsTQRTdf6eCINVHn/wxfvYjgWoM1o/ik4+2YPogilLBBwXBNqAvSprdtKnkc2d2dmZ39siZ7cZN2EgGDvfOzr3nnnt3xrv15Cdu1NtYq/3D9XqOtXobVzeOcfnBEa5U4NL9I1yrtXGz8QPkofVqBz42WgE2WwHWWz7W33DvY+sgcPbufge39zq4s5fbObzu4N5+B5utLh69DbDV6sIDMhgVIo2lA31rFJJYINUSq67f58pZz5gEw9EYk2kIGUUQQiJSCkJGbm9thrQExsfazJCkKYRKcNwNoXQKz1oLIQSklKC/uLIscyh8xo3HY4fJZAKtNZIkAWzCAHgpK1wQKqXmSMpEhWUM44k4jsF8EmpjYElYKDTGzCUvqixsFEWYTqcOJCcZwfysUEjpJKW/2GaVQpKxZZIXZDNCKuQB5ZcTl/kcDYtzdiQpFBIzhWEYztpepqzwi1gKKZPNtcwAtkKVPOQ3JlT9HCpcbJWgYl4x1zLJymABdxWWzDBy99O6wnlxzt7m14YHVMYKBP2iIvG/H5XvM+jEojuIkaQZX4rBYDDAcDjEaDRytri4BAuUCRbHQVUyTvH11xTK2Fwh50I1y9aMzGbuacpIuSdo2E2sYbRGlui85arEKqy6vKrEsrUX/vcTgcfvenh5eIbmhz7efwnQ327Arzfg1x4i2G4iDcXqhG1f4sXHM7z63Mfup3McfjvFn53n6DWfodd4itOdXaRC4i8SyVPn6E27EQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f70fb2c6b6856efdd63360bc587b27c/e4706/IP-Konfiguration_FHD-min_alt.avif 230w", "/en/static/8f70fb2c6b6856efdd63360bc587b27c/d1af7/IP-Konfiguration_FHD-min_alt.avif 460w", "/en/static/8f70fb2c6b6856efdd63360bc587b27c/5fe6f/IP-Konfiguration_FHD-min_alt.avif 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8f70fb2c6b6856efdd63360bc587b27c/a0b58/IP-Konfiguration_FHD-min_alt.webp 230w", "/en/static/8f70fb2c6b6856efdd63360bc587b27c/bc10c/IP-Konfiguration_FHD-min_alt.webp 460w", "/en/static/8f70fb2c6b6856efdd63360bc587b27c/83245/IP-Konfiguration_FHD-min_alt.webp 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8f70fb2c6b6856efdd63360bc587b27c/81c8e/IP-Konfiguration_FHD-min_alt.png 230w", "/en/static/8f70fb2c6b6856efdd63360bc587b27c/08a84/IP-Konfiguration_FHD-min_alt.png 460w", "/en/static/8f70fb2c6b6856efdd63360bc587b27c/0c69d/IP-Konfiguration_FHD-min_alt.png 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8f70fb2c6b6856efdd63360bc587b27c/0c69d/IP-Konfiguration_FHD-min_alt.png",
              "alt": "1080p WebUI Network Configuration",
              "title": "1080p WebUI Network Configuration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "598px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fbb941a651c0eb23305053b8a03e2bc7/0c69d/Mobile_Access_RTSP_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWklEQVQ4y6WUwXLCIBRF+RJ1oaOjJiQEEGISIHGtju2q/v933M7Dqe2k02laFnfYPA4X3n2w1WqF7XaLuq7RNA32+z1msxkWi8UkzedzLJdLKKWi2G63Q1VV0FqjKApsNhus1+s/ifYQh8S0NXCnAce6juA8z1GWZYRP0biWHYyB73scmwYhhOiUcz4ZOBbTxsANA9o+RGglxL9hESiVQuc9wtDDOZfk7gkkh41zkEomwT6vTG+nVOwSNSXNoZQxg9ZadF0X17SmWBsdUmzatoUxJg2otEYYhgiiKUm+MgGPXRfHJrUhj2Bbi/50Sn47zgsUvAATlQBNS5JDXkBIjlJwMJpf731syOMk/osT/q2G3MlDDlFxMPqyKC5Zlj0LPzZ91VcgzfvlfMHlfMX97Y6X2yuu5xu8C2DjH2OKQ6qnVJCssY/VWlCm2djFT8BxDcWLlOXZc+U8xzux2DYG0EIksQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fbb941a651c0eb23305053b8a03e2bc7/e4706/Mobile_Access_RTSP_04.avif 230w", "/en/static/fbb941a651c0eb23305053b8a03e2bc7/d1af7/Mobile_Access_RTSP_04.avif 460w", "/en/static/fbb941a651c0eb23305053b8a03e2bc7/5fe6f/Mobile_Access_RTSP_04.avif 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fbb941a651c0eb23305053b8a03e2bc7/a0b58/Mobile_Access_RTSP_04.webp 230w", "/en/static/fbb941a651c0eb23305053b8a03e2bc7/bc10c/Mobile_Access_RTSP_04.webp 460w", "/en/static/fbb941a651c0eb23305053b8a03e2bc7/83245/Mobile_Access_RTSP_04.webp 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fbb941a651c0eb23305053b8a03e2bc7/81c8e/Mobile_Access_RTSP_04.png 230w", "/en/static/fbb941a651c0eb23305053b8a03e2bc7/08a84/Mobile_Access_RTSP_04.png 460w", "/en/static/fbb941a651c0eb23305053b8a03e2bc7/0c69d/Mobile_Access_RTSP_04.png 598w"],
              "sizes": "(max-width: 598px) 100vw, 598px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fbb941a651c0eb23305053b8a03e2bc7/0c69d/Mobile_Access_RTSP_04.png",
              "alt": "InstarVision - add a Camera",
              "title": "InstarVision - add a Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Since the port 8081 has been `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Already Redirected`}</a>{` , accessing the camera via the app will be immediately possible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      